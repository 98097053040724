var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [_vm._v("e-mail: coral.poland@coral-club.com")]),
      _c("div", [_vm._v("Tel: +48 22 622 36 61")]),
      _c("div", [_vm._v("Telegram, Viber, WhatsApp:+48519393702")]),
      _c("div", [_vm._v("Skype: coral.poland")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }